import React, { useState } from "react";
import { useEffect } from "react";
import useEmployee from "../../../Hooks/Employee/useEmployee";
import {
  PRE_CLASS_EXAM,
  PRE_EXAM_COMMITTEE,
  REPORTS_DETAILED,
  REPORTS_DETAILED_ENG,
} from "../../../utils/Examination.apiConst";
import {
  LOCAL_COLLEGE,
  LOCAL_DEPARTMENT,
  LOCAL_PROGRAM,
} from "../../../utils/LocalStorageConstants";
import { SessionOpt1 } from "../../../Data/student/sessionData1";
//other libraries
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";
import axios from "axios";
import * as XLSX from "xlsx/xlsx.mjs";
import { RE_EXAM_EXAM } from "../../../utils/Examination.apiConst";
import "./template.css";

import {
  ACADEMICS_ADD_CLASS,
  ACADEMICS_ADD_SEMESTER,
} from "../../../utils/Academics.apiConst";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useRef } from "react";

function ReportDetailed({ setLoading }) {
  //get data from local storage

  //////program data//////

  //function to get data
  const getLocalProgramData = () =>
    localStorage.getItem(LOCAL_PROGRAM)
      ? JSON.parse(localStorage.getItem(LOCAL_PROGRAM))
      : null;

  const monthsOfYear = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [programOpt, setProgramOpt] = useState(getLocalProgramData());

  const [reExam, setReExam] = useState([]);

  useEffect(() => {
    setProgramOpt(getLocalProgramData());
  }, [localStorage.getItem(LOCAL_PROGRAM)]);

  //////College Data//////

  //function to get data
  const getLocalCollegeData = () =>
    localStorage.getItem(LOCAL_COLLEGE)
      ? JSON.parse(localStorage.getItem(LOCAL_COLLEGE))
      : null;

  const [collegeOpt, setCollegeOpt] = useState(getLocalCollegeData());

  useEffect(() => {
    setCollegeOpt(getLocalCollegeData());
  }, [localStorage.getItem(LOCAL_COLLEGE)]);

  //////Department Data//////

  //function to get data
  const getLocalDeptData = () =>
    localStorage.getItem(LOCAL_DEPARTMENT)
      ? JSON.parse(localStorage.getItem(LOCAL_DEPARTMENT))
      : null;

  const [DeptOpt, setDeptOpt] = useState(getLocalDeptData());

  useEffect(() => {
    setDeptOpt(getLocalDeptData());
  }, [localStorage.getItem(LOCAL_DEPARTMENT)]);

  const today = new Date();

  // Get day, month, and year components
  const day = today.getDate().toString().padStart(2, "0");
  const month = (today.getMonth() + 1).toString().padStart(2, "0");
  const year = today.getFullYear();

  // Format the date as dd-mm-yyyy
  const formattedDate = `${day}-${month}-${year}`;
  const facultyData = [
    {
      id: 1111000,
      name: "FACULTY OF ENGINEERING AND TECHNOLOGY",
      college_type_id: "ENG",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan College Engineering and Technology",
    },
    {
      id: 1111001,
      name: "FACULTY OF AYURVEDA",
      college_type_id: "AYU",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Ayurvedic College",
    },
    {
      id: 1111002,
      name: "FACULTY OF LAW",
      college_type_id: "LAW",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Law College",
    },
    {
      id: 1111003,
      name: "FACULTY OF NURSING",
      college_type_id: "NUR",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan  College of Nursing",
    },
    {
      id: 1111004,
      name: "FACULTY OF ARTS AND HUMANITIES ",
      college_type_id: "NUR",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Arts  College",
    },
    {
      id: 1111005,
      name: "FACULTY OF COMMERCE AND MANAGEMENT",
      college_type_id: "COM",
      status: "ACTIVE",
      colloge_name: "FACULTY OF COMMERCE AND MANAGEMENT",
    },
    {
      id: 1111010,
      name: "Faculty of Education",
      college_type_id: "EDU",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan B.Ed College",
      program_name: "Bachelor of Education ",
    },
    {
      id: 1111011,
      name: "FACULTY OF SCIENCE",
      college_type_id: "SCI",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan  Science College",

      program_name: "Bachelor of Science ",
    },
    {
      id: 1111007,
      name: "FACULTY OF HOMEOPATHY",
      college_type_id: "HOMEOPATHY",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Homoeopathy College",
    },

    {
      id: 1111008,
      name: "FACULTY OF PHARMACY",
      college_type_id: "PHA",
      status: "ACTIVE",
      colloge_name: "Shree Swaminarayan Pharmacy College",
    },
  ];

  const onExport = () => {
    let json_data = [];
    let cnt = 0;
    for (const i of reportStat?.studentData) {
      const arr2 = reportStat.marks.filter((s) => s.student_id == i.student_id);

      const arr3 = reportStat2.marks.filter(
        (s) => s.student_id == i.student_id
      );

      if (cnt == 0) {
        console.log(arr3);
      }
      cnt++;
      const collegeId = arr2[0]?.college_id;
      const deptId = arr2[0]?.department_id;

      let dob = new Date(i?.dob);

      let dodate =
        dob.getDate() +
        "-" +
        parseInt(dob.getMonth() + 1) +
        "-" +
        dob.getFullYear();
      console.log(dodate);
      // console.log(i?.e_session.substring(0));
      let session = i?.e_session?.toString();
      console.log(typeof i?.e_session);
      session = session.slice(2, 4);

      let sub = 1;
      const obj = {
        ORG_NAME: facultyData?.find((s) => s.id == collegeId)?.colloge_name,
        // ORG_NAME_L: "",
        // ORG_ADDRESS:"Swaminarayan University,Shree Swaminarayan Vishvamangal Gurukul,Ahmedabad-Mehsana Highway,At&Po-Saij, Tal.-Kalol Dist-Gandhinagar-382725,Gujarat,India",
        // ORG_CITY:"Kalol",
        // ORG_STATE:"Gujarat",
        // ORG_PIN:"382725"

        ACADEMIC_COURSE_ID: i?.student_id.substring(5, 9),

        COURSE_NAME: DeptOpt?.find((s) => s.id == deptId)?.name,
        // COURSE_NAME_L: DeptOpt?.find((s) => s.id == deptId)?.name,
        STREAM: "",
        // STREAM_L: "",
        // STREAM_SECOND:"",
        // STREAM_SECOND_L:"",
        // SPECIALIZATION_MAJOR:"",
        // SPECIALIZATION_MINOR:"",
        SESSION: i?.e_session - 1 + "-" + session,
        REGN_NO: i?.student_id,
        RROLL: i?.student_id,
        // AADHAAR_NO: i?.aadhar_number,
        // LOCKER_ID:"",
        // ABC_ACCOUNT_ID: i?.abc_number,
        CNAME: i?.name,
        GENDER: i?.gender?.charAt(0).toUpperCase(),
        DOB: dodate?.split("T")[0],
        // BLOOD_GROUP:"",
        // CASTE: i?.caste,
        // RELIGION:"",
        // NATIONALITY: i?.nationality,
        // PH:"",
        // MOBILE: i?.phone,
        // EMAIL: i?.email,
        FNAME: i?.father_name,
        MNAME: i?.mother_name,
        // GNAME:"",
        // STUDENT_ADDRESS: (i?.current_address == "" || i?.current_address ==null) ? i?.address : i?.current_address  ,
        PHOTO: "",
        MRKS_REC_STATUS: "O",

        RESULT: AnalyseResult(i?.student_id) ? "Pass" : "Fail",
        // RESULT_TH:"",
        // RESULT_PR:"",
        YEAR: i?.e_year.split("T")[0].split("-")[0],
        MONTH: i?.e_year.split("T")[0].split("-")[1],
        // DIVISION: "",
        // GRADE: calculateGradePointEachSub(
        //   Number(i?.e_marks) +
        //     (i?.e_grace_marks ? Number(i?.e_grace_marks) : 0) +
        //     Number(i?.i_marks),
        //   Number(i?.e_max) + Number(i?.i_max)
        // )?.grade,
        PERCENT: (
          (reportStat?.marks
            ?.filter(
              (s) => s?.student_id == i?.student_id && !s.is_not_countable
            )
            ?.reduce(
              (total, cur) =>
                total + (Number(cur?.e_marks) + Number(cur?.i_marks)),
              0
            ) *
            100) /
          reportStat?.marks
            ?.filter(
              (s) => s?.student_id == i?.student_id && !s.is_not_countable
            )
            ?.reduce(
              (total, cur) => total + (Number(cur?.e_max) + Number(cur?.i_max)),
              0
            )
        ).toFixed(2),
        // DOR:"",
        DOI: "",
        // DOV:"",
        // DOP:"",
        // DOQ:"",
        // DOS:"",
        // THESIS:"",
        CERT_NO: "",
        SEM:
          user?.college_id == 1111001
            ? classOpt?.filter((s) => s.id == user?.class_id)[0]?.name
            : semesterOpt?.filter((s) => s.id == user?.semester_id)[0]?.name,

        // EXAM_TYPE: "REGULAR",

        TOT: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce(
            (total, cur) => total + (Number(cur?.e_max) + Number(cur?.i_max)),
            0
          ),

        TOT_MIN: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce(
            (total, cur) => total + (Number(cur?.e_min) + Number(cur?.i_min)),
            0
          ),
        TOT_MRKS: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce(
            (total, cur) =>
              total + (Number(cur?.e_marks) + Number(cur?.i_marks)),
            0
          ),
        // TOT_MRKS_WRDS: converter.toWords(reportStat?.marks
        //   ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
        //   ?.reduce(
        //     (total, cur) =>
        //       total + (Number(cur?.e_marks) + Number(cur?.i_marks)),
        //     0
        //   )) ,
        TOT_TH_MAX: reportStat?.marks
          ?.filter(
            (s) =>
              s?.student_id == i?.student_id &&
              !s.is_not_countable &&
              s?.type == "LECTURE"
          )
          ?.reduce((total, cur) => total + Number(cur?.e_max), 0),
        TOT_TH_MIN: reportStat?.marks
          ?.filter(
            (s) =>
              s?.student_id == i?.student_id &&
              !s.is_not_countable &&
              s?.type == "LECTURE"
          )
          ?.reduce((total, cur) => total + Number(cur?.e_min), 0),
        TOT_TH_MRKS: reportStat?.marks
          ?.filter(
            (s) =>
              s?.student_id == i?.student_id &&
              !s.is_not_countable &&
              s?.type == "LECTURE"
          )
          ?.reduce((total, cur) => total + Number(cur?.e_marks), 0),
        TOT_PR_MAX: reportStat?.marks
          ?.filter(
            (s) =>
              s?.student_id == i?.student_id &&
              !s.is_not_countable &&
              s?.type != "LECTURE"
          )
          ?.reduce((total, cur) => total + Number(cur?.e_max), 0),
        TOT_PR_MIN: reportStat?.marks
          ?.filter(
            (s) =>
              s?.student_id == i?.student_id &&
              !s.is_not_countable &&
              s?.type != "LECTURE"
          )
          ?.reduce((total, cur) => total + Number(cur?.e_min), 0),
        TOT_PR_MRKS: reportStat?.marks
          ?.filter(
            (s) =>
              s?.student_id == i?.student_id &&
              !s.is_not_countable &&
              s?.type != "LECTURE"
          )
          ?.reduce((total, cur) => total + Number(cur?.e_marks), 0),
        TOT_CE_MAX: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce((total, cur) => total + Number(cur?.i_max), 0),
        TOT_CE_MIN: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce((total, cur) => total + Number(cur?.i_min), 0),
        TOT_CE_MRKS: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce((total, cur) => total + Number(cur?.i_marks), 0),
        TOT_VV_MAX: "",
        TOT_VV_MIN: "",
        TOT_VV_MRKS: "",
        // GRAND_TOT_MAX:reportStat?.marks
        // ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
        // ?.reduce(
        //   (total, cur) =>
        //     total + (Number(cur?.e_max) + Number(cur?.i_max)),
        //   0
        // ),
        //           GRAND_TOT_MIN:reportStat?.marks
        // ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
        // ?.reduce(
        //   (total, cur) =>
        //     total + (Number(cur?.e_min) + Number(cur?.i_min)),
        //   0
        // ),
        // GRAND_TOT_MRKS:reportStat?.marks
        // ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
        // ?.reduce(
        //   (total, cur) =>
        //     total + (Number(cur?.e_marks) + Number(cur?.i_marks)),
        //   0
        // ),
        TOT_CREDIT: reportStat.marks
          .filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce((total, j) => total + Number(j?.credit), 0),

        TOT_CREDIT_POINTS:
          i?.credit == 0 || i?.credit == null
            ? ""
            : reportStat?.marks
                ?.filter(
                  (s) => s?.student_id == i?.student_id && !s.is_not_countable
                )
                ?.reduce(
                  (total, j) =>
                    total +
                    Number(
                      calculateGradePoint(
                        ((Number(j?.i_marks) +
                          Number(j?.e_marks) +
                          Number(j?.e_grace_marks)) /
                          (Number(j?.e_max) + Number(j?.i_max))) *
                          Number(j?.credit)
                      )
                    ),

                  0
                )
                .toFixed(2),
        TOT_GRADE_POINTS: "",
        GRAND_TOT_MAX: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce(
            (total, cur) => total + (Number(cur?.e_max) + Number(cur?.i_max)),
            0
          ),

        GRAND_TOT_MIN: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce(
            (total, cur) => total + (Number(cur?.e_min) + Number(cur?.i_min)),
            0
          ),
        GRAND_TOT_MRKS: reportStat?.marks
          ?.filter((s) => s?.student_id == i?.student_id && !s.is_not_countable)
          ?.reduce(
            (total, cur) =>
              total +
              (Number(cur?.e_marks) +
                Number(cur?.e_grace_marks) +
                Number(cur?.i_marks)),
            0
          ),
        GRAND_TOT_CREDIT: "",
        // GRAND_TOT_CREDIT_POINTS:"",
        CGPA: "",
        REMARKS: "",
        SGPA: (
          reportStat?.marks
            ?.filter(
              (s) => s?.student_id == i?.student_id && !s.is_not_countable
            )
            ?.reduce(
              (total, j) =>
                total +
                Number(
                  calculateGradePoint(
                    ((Number(j?.i_marks) +
                      Number(j?.e_marks) +
                      Number(j?.e_grace_marks)) /
                      (Number(j?.e_max) + Number(j?.i_max))) *
                      Number(j?.credit)
                  )
                ),

              0
            ) /
          reportStat?.marks
            ?.filter(
              (s) => s?.student_id == i?.student_id && !s.is_not_countable
            )
            ?.reduce((total, j) => total + Number(j?.credit), 0)
        ).toFixed(2),

        ABC_ACCOUNT_ID: i?.abc_number,
        TERM_TYPE: "",
        TOT_GRADE: "",

        //   TOT_GRADE_POINTS: (calculateGradePointEachSub(
        //     Number(i?.e_marks) +
        //     (i?.e_grace_marks ? Number(i?.e_grace_marks) : 0)+Number(i?.i_marks),
        //     Number(i?.e_max) + Number(i?.i_max)
        //   )?.gp).toFixed(2)
      };
      // console.log(reportStat.marks)
      // console.log(Number(i?.e_marks) + "+" + Number(i?.i_marks));
      for (const j of arr2) {
        // obj=["ABC_ACCOUNT_ID"]=j?.abc_number;

        obj["SUB" + sub + "NM"] = j?.subject;
        obj["SUB" + sub] = j?.sub_code;
        obj["SUB" + sub + "MAX"] = j?.e_max + j?.i_max;
        obj["SUB" + sub + "MIN"] = j?.e_min + j?.i_min;
        obj["SUB" + sub + "_TH_MAX"] =
          arr3
            ?.filter((s) => s.sub_code == j?.sub_code && s.type == "LECTURE")
            ?.reduce((acc, it) => acc + it.e_max, 0) +
          arr3
            ?.filter((s) => s.sub_code == j?.sub_code && s.type == "LECTURE")
            ?.reduce((acc, it) => acc + it.i_max, 0);
        obj["SUB" + sub + "_VV_MRKS"] = "";
        obj["SUB" + sub + "_PR_CE_MRKS"] =
          j?.type == "PRACTICAL" ? j?.i_min : " ";

        obj["SUB" + sub + "_TH_MIN"] = j?.type == "LECTURE" ? j?.e_min : " ";
        obj["SUB" + sub + "_PR_MAX"] =
          arr3
            ?.filter((s) => s.sub_code == j?.sub_code && s.type == "PRACTICAL")
            ?.reduce((acc, it) => acc + it.e_max, 0) +
          arr3
            ?.filter((s) => s.sub_code == j?.sub_code && s.type == "PRACTICAL")
            ?.reduce((acc, it) => acc + it.i_max, 0);

        obj["SUB" + sub + "_PR_MIN"] =
          j?.type == "PRACTICAL" ? j?.e_min + j?.i_min : " ";

        obj["SUB" + sub + "_CE_MAX"] = j?.i_max;
        obj["SUB" + sub + "_CE_MIN"] = j?.i_min;

        obj["SUB" + sub + "_TH_MRKS"] =
          arr3
            ?.filter((s) => s.sub_code == j?.sub_code && s.type == "LECTURE")
            ?.reduce((acc, it) => acc + it.e_marks, 0) +
          arr3
            ?.filter((s) => s.sub_code == j?.sub_code && s.type == "LECTURE")
            ?.reduce((acc, it) => acc + it.i_marks, 0);

        obj["SUB" + sub + "_PR_MRKS"] =
          arr3
            ?.filter((s) => s.sub_code == j?.sub_code && s.type == "PRACTICAL")
            ?.reduce((acc, it) => acc + it.e_marks, 0) +
          arr3
            ?.filter((s) => s.sub_code == j?.sub_code && s.type == "PRACTICAL")
            ?.reduce((acc, it) => acc + it.i_marks, 0);

        obj["SUB" + sub + "_CE_MRKS"] = j?.i_marks;

        obj["SUB" + sub + "_TOT"] = j?.e_marks + j?.e_grace_marks + j?.i_marks;

        obj["SUB" + sub + "_GRADE"] =
          calculateGradePointEachSub(
            Number(j?.e_marks) + Number(j?.i_marks) + j?.e_grace_marks
              ? Number(j?.e_grace_marks)
              : 0,
            Number(j?.e_max) + Number(j?.i_max)
          )?.grade == null
            ? "--"
            : calculateGradePointEachSub(
                Number(j?.e_marks) +
                  Number(j?.i_marks) +
                  (j?.e_grace_marks ? Number(j?.e_grace_marks) : 0),
                Number(j?.e_max) + Number(j?.i_max)
              )?.grade;

        obj["SUB" + sub + "_GRADE_POINTS"] =
          j?.e_grace_marks != null
            ? (
                ((Number(j?.e_marks) +
                  Number(j?.e_grace_marks) +
                  Number(j?.i_marks)) *
                  100) /
                (Number(j?.e_max) + Number(j?.i_max))
              ).toFixed(2) / 10
            : (
                ((Number(j?.e_marks) + Number(j?.i_marks)) * 100) /
                (Number(j?.e_max) + Number(j?.i_max)) /
                10
              ).toFixed(2);

        obj["SUB" + sub + "_CREDIT"] = j?.credit;
        obj["SUB" + sub + "_CREDIT_POINTS"] = calculateGradePoint(
          ((Number(j?.i_marks) +
            Number(j?.e_marks) +
            Number(j?.e_grace_marks)) /
            (Number(j?.e_max) + Number(j?.i_max))) *
            Number(j?.credit)
        );

        // obj["SUB" + sub + "_GRACE"] =  Number(j?.e_grace_marks);
        obj["SUB" + sub + "_REMARKS"] =
          Number(j?.e_marks) + Number(j?.e_grace_marks) >= Number(j?.e_min) &&
          Number(j?.i_marks >= j?.i_min)
            ? "CL"
            : j?.e_absent || j?.i_absent
            ? "NCL"
            : "NCL";
        //     obj["SUB" + sub + "STATUS"] =
        //   (Number(j?.e_marks) + Number(j?.e_grace_marks) + Number(j?.i_marks) ) > (Number(j?.e_min) + Number (j?.i_min)
        //  )
        //     ? "PASS"
        //     : j?.e_absent || j?.i_absent
        //     ? "FAIL"
        //     : "FAIL";
        // console.log("Subject Name" + j?.e_absent);
        obj["SUB" + sub + "_VV_MIN"] = j?.viva_min;
        obj["SUB" + sub + "_VV_MAX"] = j?.viva_max;

        obj["SUB" + sub + "_TH_CE_MRKS"] =
          j?.type == "LECTURE" ? j?.j?.i_marks : " ";

        obj["SUB" + sub + "_CREDIT_ELIGIBILITY"] = "";

        sub++;
        // submarks++
      }
      let obj1 = {
        ...obj,
        AADHAAR_NAME: i?.name.toUpperCase(),
        ADMISSION_YEAR: i?.year_of_admission,
      };
      json_data.push(obj1);
    }
    var worksheet = XLSX.utils.json_to_sheet(json_data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, worksheet);
    XLSX.writeFile(wb, `Exam  Detail Report.csv`);

    console.log(json_data);
  };

  const maptoHundred = (num, max) => (100 * Number(num)) / Number(max);

  const calculateGradePointEachSub = (num, max) => {
    // console.log(d);
    let marks = maptoHundred(Number(num), Number(max));
    // console.log(num, max);

    for (const i of gparef) {
      if (i.min <= marks && i.max >= marks) {
        // console.log(i, marks);
        return i;
      }
    }
  };

  const gparef = [
    {
      min: 85,
      max: 100,
      grade: "O+",
      gp: 10,
    },

    {
      min: 70,
      max: 84.99,
      grade: "O",
      gp: 8,
    },
    {
      min: 60,
      max: 69.99,
      grade: "A",
      gp: 7,
    },
    {
      min: 55,
      max: 59.99,
      grade: "B+",
      gp: 6,
    },
    {
      min: 48,
      max: 54.99,
      grade: "B",
      gp: 5,
    },
    {
      min: 36,
      max: 47.99,
      grade: "C",
      gp: 4,
    },
    {
      min: 0,
      max: 35.99,
      grade: "D",
      gp: 0,
    },
  ];

  const newGparef = [
    {
      min: 96,
      max: 100,
      grade: "O",
      gp: 10,
    },

    {
      min: 86,
      max: 95.99,
      grade: "A+",
      gp: 9,
    },
    {
      min: 76,
      max: 85.99,
      grade: "A",
      gp: 8,
    },
    {
      min: 66,
      max: 75.99,
      grade: "B+",
      gp: 7,
    },
    {
      min: 56,
      max: 65.99,
      grade: "B",
      gp: 6,
    },
    {
      min: 46,
      max: 55.99,
      grade: "C",
      gp: 5,
    },
  ];

  const [flag1, setFlag1] = useState(false);

  useEffect(() => {
    if (flag1) {
      setTimeout(() => {
        setFlag1(false);
      }, 10000);
    }
  }, [flag1]);

  /////////UseStates//////////////
  ///main Object fro search
  const [user, setUser] = useState({
    program_id: "",
    college_id: "",
    class_id: "",
    semester_id: "",
    month: "",
    year: "",
    department_id: "",
  });

  //main object for data manipulation
  const [mainUser, setMainUser] = useState({
    class_examination_id: "",
    course_id: "",
    date: "",
    time_from: "",
    time_to: "",
    duration: "",
    max_marks: "",
    min_marks: "",
  });

  // Ref for table to export in excel
  const tableRef = useRef();
  const tableRef1 = useRef();
  const PrintReceipt = useReactToPrint({
    content: () => tableRef1.current,
  });
  const tableRefNep = useRef();

  const PrintReceipt1 = useReactToPrint({
    content: () => tableRefNep.current,
  });

  const handlePrint = () => {
    let x = true;
    setFlag1(x);
    PrintReceipt();
  };

  const handlePrint1 = () => {
    let x = true;
    setFlag1(x);
    PrintReceipt1();
  };
  //main data
  const [data, setData] = useState([]);

  //class data
  const [classOpt, setClassOpt] = useState([]);

  //semster data
  const [semesterOpt, setSemesterOpt] = useState([]);

  //employee data of college
  const [empOpt, setEmpOpt] = useEmployee(user?.college_id);

  //Employee Data of commitee
  const [employeeOpt, setEmployeeOpt] = useState([]);

  // Flag to show or hide reports
  const [flag, setFlag] = useState(0);
  const semArr = [
    {
      name: "First Semester",
      value: "I",
    },
    {
      name: "Second Semester",
      value: "II",
    },
    {
      name: "Third Semester",
      value: "III",
    },
    {
      name: "Fourth Semester",
      value: "IV",
    },
    {
      name: "Fifth Semester",
      value: "V",
    },
    {
      name: "Sixth Semester",
      value: "VI",
    },
    {
      name: "Seventh Semester",
      value: "VII",
    },
    {
      name: "Eighth Semester",
      value: "VIII",
    },
  ];

  ///////Other states for management//////////

  const [showProgram, setShowProgram] = useState();
  const [showCollege, setShowCollege] = useState();
  const [showDepartment, setShowDepartment] = useState();
  const [showMonth, setShowMonth] = useState();
  const [showYear, setShowYear] = useState();

  const [reportStat, setReportStat] = useState([]);
  const [reportStat2, setReportStat2] = useState([]);
  const [prevExamData, setPrevExamData] = useState([]);

  const [examId, setExamId] = useState();

  ///////////Helping functions ///////////
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const calculateGradePoint = (p) => {
    // if(p>=85) return "O+"
    // if (p>=70 && p<=84.99 ) return "O"
    // if (p>=60    && p<=69.99 ) return "A"
    // if (p>=55 && p<=59.99 ) return "B+"
    // if (p>=48 && p<=54.99 ) return "B"
    // if (p>=36 && p<=47.99 ) return "C"
    // if (p<36) return "D"

    // console.log(p.toFixed(2))

    return parseFloat(p.toFixed(2) * 10).toFixed(2);
  };

  const calculateGrade = (p) => {
    if (p >= 85) return "O+";
    if (p >= 70 && p <= 84.99) return "O";
    if (p >= 60 && p <= 69.99) return "A";
    if (p >= 55 && p <= 59.99) return "B+";
    if (p >= 48 && p <= 54.99) return "B";
    if (p >= 36 && p <= 47.99) return "C";
    if (p < 36) return "D";
    console.log(p);
  };

  const calculateGrade1 = (p) => {
    if (p >= 96) return "O";
    if (p >= 86 && p <= 95.99) return "A+";
    if (p >= 76 && p <= 85.99) return "A";
    if (p >= 66 && p <= 75.99) return "B+";
    if (p >= 56 && p <= 65.99) return "B";
    if (p >= 46 && p <= 55.99) return "C";
    console.log(p);
  };
  /////////function calls from db to fe///////////
  //get all required data for creating a exam time tabel i.e, class and semester
  const getBasicData = async () => {
    const config = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    var flag = 0;

    const [data1, data2] = await Promise.all([
      axios({
        ...config,
        url: `${ACADEMICS_ADD_CLASS}?college_id=${user?.college_id}`,
      })
        .then((res) => {
          setClassOpt(res.data.data);
        })
        .catch((err) => (flag = 1)),
      axios({
        ...config,
        url: `${ACADEMICS_ADD_SEMESTER}?college_id=${user?.college_id}`,
      })
        .then((res) => {
          setSemesterOpt(res.data.data);
        })
        .catch((err) => (flag = 1)),
      ,
    ]);

    if (flag) return toast.error("Something went wrong");
  };

  //Get Exam Details
  const getData = async (exam_id) => {
    setLoading(1);
    const config = {
      method: "get",
      url: `${PRE_CLASS_EXAM}?exam_committee_id=${exam_id}&class_id=${user?.class_id}&semester_id=${user?.semester_id}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data);
      })
      .catch((err) => console.log(err));

    await setShowCollege(user?.college_id);
    await setShowProgram(user?.program_id);
    await setShowMonth(user?.month);
    await setShowYear(user?.year);
    await setShowDepartment(user?.department_id);
    setLoading(0);
  };

  let img = "./ResultsTemplate.jpg";
  const AnalyseResult = (p) => {
    const arr = reportStat?.marks?.filter((s) => s.student_id == p);
    for (const i of arr) {
      if (i?.e_absent == 1) return false;
      if (i?.e_min > i?.e_marks + Number(i?.e_grace_marks)) return false;
    }
    return true;
  };

  //Get Committee Data
  const getCommiittee = async () => {
    if (
      !user.program_id ||
      !user.college_id ||
      !user.month ||
      !user.year ||
      !user?.class_id ||
      !user?.semester_id
    )
      return toast.error("All fileds are required for search");
    await setExamId();
    setFlag(0);
    setLoading(1);

    const config = {
      method: "get",
      url: `${PRE_EXAM_COMMITTEE}?program_id=${user.program_id}&college_id=${user.college_id}&month=${user.month}&year=${user.year}`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(res.data.data);
        if (res.data.data.length != 0) {
          const m = [];
          empOpt?.map((s) => {
            if (res.data.data[0]?.employee?.find((k) => k == s?.id)) m.push(s);
          });
          setEmployeeOpt(m);
          setMainUser((prev) => ({
            ...prev,
            exam_committee_id: res.data.data[0].id,
          }));
          getBasicData();
          getData(res.data.data[0].id);
        } else {
          toast.warning("Committe is not exist");
        }
      })
      .catch((err) => {
        setLoading(0);
        toast.error("Something Went Wrong");
      });

    const config1 = {
      method: "get",
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios({
      ...config1,
      url: `${RE_EXAM_EXAM}?college_id=${user?.college_id}&program_id=${user?.program_id}&class_id=${user?.class_id}&semester_id=${user?.semester_id}`,
    })
      .then((res) => {
        console.log(res.data.data);
        setLoading(0);
        setReExam(res.data.data);
      })
      .catch((err) => {
        setLoading(0);
      });
  };

  const getReports = async () => {
    setLoading(1);

    let url =
      user?.college_id == "1111000"
        ? `${REPORTS_DETAILED_ENG}/${examId}`
        : `${REPORTS_DETAILED}/${examId}?reExamId=${reExam[0]?.id}`;
    const config = {
      method: "get",
      url: url,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
        "Content-Type": "application/json",
      },
    };

    await axios(config)
      .then((res) => {
        console.log(
          "Confusing Data",
          res.data.data,
          res.data.data?.marks?.filter((s) => s.student_id == "SUK220104BA006")
        );
        const sortedArr = res.data.data?.studentData.sort((a, b) => {
          // Extract the numeric part by using a regular expression
          const numA = parseInt(a?.student_id?.match(/\d+$/)[0], 10);
          const numB = parseInt(b?.student_id?.match(/\d+$/)[0], 10);

          return numA - numB;
        });
        let arr = [];
        for (const i of res.data.data.studentData) {
          const one_student_data = res.data.data.marks.filter(
            (s) => s.student_id == i.student_id
          );
          const result_studentData = Object.values(
            one_student_data.reduce((acc, curr) => {
              const subject = curr.sub_code;
              if (!acc[subject]) {
                acc[subject] = { ...curr };
                acc[subject].e_marks = Number(acc[subject].e_marks);
                acc[subject].e_max = Number(acc[subject].e_max);
                acc[subject].e_min = Number(acc[subject].e_min);
                acc[subject].i_marks = Number(acc[subject].i_marks);
                acc[subject].i_max = Number(acc[subject].i_max);
                acc[subject].i_min = Number(acc[subject].i_min);
                acc[subject].credit = Number(acc[subject].credit);
                acc[subject].e_grace_marks = Number(curr.e_grace_marks);
              } else {
                acc[subject].e_marks += Number(curr.e_marks);
                acc[subject].e_max += Number(curr.e_max);
                acc[subject].e_min += Number(curr.e_min);
                acc[subject].i_marks += Number(curr.i_marks);
                acc[subject].i_max += Number(curr.i_max);
                acc[subject].i_min += Number(curr.i_min);
                acc[subject].credit += Number(curr.credit);
                acc[subject].e_grace_marks += Number(curr.e_grace_marks);
              }
              return acc;
            }, {})
          );
          arr = [...arr, ...result_studentData];
        }
        // console.log({ marks: arr, studentData: res.data.data.studentData })
        setReportStat({ marks: arr, studentData: sortedArr });
        setPrevExamData(res.data.data.prevExamData);
        setReportStat2(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(0);
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  useEffect(() => {
    getBasicData();
  }, []);

  useEffect(() => {
    if (examId) getReports();
  }, [examId]);

  return (
    <div className="CreateExamTimetable">
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h4 className="mb-0">Students Marks Report</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">
                        <a href="javascript: void(0);">Examination</a>
                      </li>
                      <li className="breadcrumb-item active">
                        {" "}
                        Students Marks Report
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body">
                    <h2 className="card-title">Select Criteria</h2>
                    <br />

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="course">
                            Program<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            class="form-control"
                            name="program_id"
                            id="course"
                            onChange={handleChange}
                            value={user?.program_id}
                          >
                            <option value="">Select Program</option>
                            {programOpt?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="faculty">
                            Faculty<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            class="form-control"
                            name="college_id"
                            id="faculty"
                            value={user?.college_id}
                            onChange={handleChange}
                          >
                            <option value="">Select Faculty</option>
                            {collegeOpt?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Year</label>
                          <input
                            type="month"
                            className="form-control"
                            name="year"
                            id="year"
                            value={user?.year + "-" + user?.month}
                            onChange={(e) => {
                              setUser((prev) => ({
                                ...prev,
                                month: e.target.value?.split("-")[1],
                                year: e.target.value?.split("-")[0],
                              }));
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Department</label>
                          <select
                            name="department_id"
                            onChange={handleChange}
                            value={user?.department_id}
                            className="form-control"
                          >
                            <option value="">Select Department</option>
                            {DeptOpt?.filter(
                              (s) => s.college_id == user?.college_id
                            )?.map((i, key) => (
                              <option value={i?.id} key={key}>
                                {i?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Class</label>
                          <select
                            name="class_id"
                            value={user?.class_id}
                            onChange={handleChange}
                            id=""
                            className="form-control"
                          >
                            <option value="">Select Class</option>
                            {classOpt
                              ?.filter(
                                (s) => s.department_id == user?.department_id
                              )
                              ?.map((i, key) => (
                                <option value={i?.id} key={key}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="">Semester</label>
                          <select
                            name="semester_id"
                            value={user?.semester_id}
                            onChange={handleChange}
                            id=""
                            className="form-control"
                          >
                            <option value="">Select Semester</option>
                            {semesterOpt
                              ?.filter((s) => s.class_id == user?.class_id)
                              ?.map((i, key) => (
                                <option value={i?.id} key={key}>
                                  {i?.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      {/* <div className="col-md-3">
                    <div className="form-group">
                      <label htmlFor="">Semester</label>
                      <select name="sem" className="form-control" id="sem">
                        <option value="">Select </option>
                        <option value="">1st Semester</option>
                      </select>
                    </div>
                  </div> */}
                    </div>
                    <div className="row ">
                      <div className="col-md-12 ">
                        <button
                          className="btn btn-nex btn-rounded float-right  "
                          type="submit"
                          name="submit"
                          onClick={getCommiittee}
                        >
                          <i className="fa fa-search" aria-hidden="true" />{" "}
                          Search
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end card */}
              </div>
            </div>

            {!flag ? (
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <h4 className="card-title text-uppercase ">List</h4>
                      <hr />

                      <div className="table-responsive">
                        <table
                          id=""
                          className="display table table-bordered  nowrap table-hover "
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                          }}
                        >
                          <thead>
                            <tr>
                              <th> Sl. No.</th>
                              <th>Program</th>
                              <th>Faculty</th>
                              <th>Date</th>

                              <th>Staff</th>

                              <th>Class</th>

                              <th>Semester</th>

                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data &&
                              data?.map((i, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{key + 1}</td>
                                    <td>
                                      {
                                        programOpt?.find(
                                          (s) => s.id == showProgram
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        collegeOpt?.find(
                                          (s) => s.id == showCollege
                                        )?.name
                                      }
                                    </td>
                                    <td> {showMonth + "," + showYear}</td>
                                    <td>
                                      {" "}
                                      {i?.employee?.map((j, key2) => (
                                        <div>
                                          {key2 + 1}.{" "}
                                          {
                                            employeeOpt?.find((s) => s.id == j)
                                              ?.first_name
                                          }
                                        </div>
                                      ))}
                                    </td>
                                    <td>
                                      {" "}
                                      {
                                        classOpt?.find(
                                          (s) => s.id == i.class_id
                                        )?.name
                                      }
                                    </td>
                                    <td>
                                      {
                                        semesterOpt
                                          ?.filter(
                                            (s) => s.class_id == user?.class_id
                                          )
                                          ?.filter(
                                            (s) => s.id == user?.semester_id
                                          )[0]?.name
                                      }
                                      {/* {
                                        semesterOpt?.find(
                                          (s) => s.id == i.semester_id
                                        )?.name
                                      } */}
                                    </td>

                                    <td>
                                      <a
                                        className="btn btn-success btn-sm p-2 text-white mr-3"
                                        title="Create Timetable"
                                        onClick={() => {
                                          setFlag(1);
                                          setExamId(i.id);
                                        }}
                                      >
                                        <i
                                          class="ri-article-line "
                                          aria-hidden="true"
                                        >
                                          Results
                                        </i>
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <button
                          onClick={() => setFlag(0)}
                          className="btn btn-primary mr-auto p-2"
                        >
                          Back
                        </button>
                        <button
                          onClick={onDownload}
                          className="btn btn-success mr-2"
                        >
                          Export
                        </button>
                        <button
                          onClick={onExport}
                          className="btn btn-success mr-2 p-2"
                        >
                          CSV
                        </button>
                        <button
                          onClick={handlePrint}
                          className="btn btn-success p-2"
                        >
                          Print Marksheet
                        </button>
                        {/* <button
                          onClick={handlePrint1}
                          className="btn btn-success p-2 ml-2"
                        >
                          Print NEP Marksheet
                        </button> */}
                      </div>
                      <h4 className="card-title text-uppercase ">List</h4>
                      <hr />
                      <div className="table-responsive">
                        <table
                          id=""
                          className="display table reportmarkstable table-bordered  nowrap table-hover "
                          style={{
                            borderCollapse: "collapse",
                            borderSpacing: 0,
                            width: "100%",
                            fontSize: "14px",
                            padding: "0px",
                          }}
                          ref={tableRef}
                        >
                          <tbody>
                            {reportStat?.studentData?.map((i, key) => (
                              <>
                                <tr>
                                  <td rowSpan={8}>{key + 1}</td>
                                  <td rowSpan={8}>{i?.student_id}</td>

                                  <td colSpan={2}>
                                    <table
                                      className="reportmarkstable"
                                      style={{
                                        fontSize: "14px",
                                        padding: "0px",
                                      }}
                                    >
                                      <tr>
                                        <th
                                          colSpan={
                                            reportStat2?.marks?.filter(
                                              (s) =>
                                                s?.student_id == i?.student_id
                                            ).length - 3
                                          }
                                        >
                                          {i?.name}
                                        </th>
                                        <th className="text-center">
                                          {user?.college_id == 1111001
                                            ? classOpt?.filter(
                                                (s) => s.id == user?.class_id
                                              )[0]?.name
                                            : semesterOpt?.filter(
                                                (s) => s.id == user?.semester_id
                                              )[0]?.name}
                                        </th>
                                        <th className="text-center">
                                          {" "}
                                          {monthsOfYear[
                                            parseInt(showMonth, 10) - 1
                                          ] +
                                            "," +
                                            showYear}
                                        </th>

                                        <th className="text-center" colSpan={2}>
                                          {showYear - 1 + "-" + showYear}{" "}
                                        </th>
                                      </tr>

                                      <tr>
                                        <th>Summary</th>
                                        {reportStat2?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <th className="text-center">
                                              {j?.subject}
                                              <br />{" "}
                                              <span
                                                className="text-primary"
                                                style={{ fontSize: "12px" }}
                                              >
                                                ( {j?.type} )
                                              </span>
                                            </th>
                                          ))}
                                      </tr>

                                      <tr>
                                        <th>External Max</th>
                                        {reportStat2?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <td className="text-center">
                                              {j?.e_max == 0 || j?.e_max == null
                                                ? "--"
                                                : j?.e_max}
                                            </td>
                                          ))}
                                      </tr>
                                      <tr>
                                        <th>External Min</th>
                                        {reportStat2?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <td className="text-center">
                                              {j?.e_min == 0 || j?.e_min == null
                                                ? "--"
                                                : j?.e_min}
                                            </td>
                                          ))}
                                      </tr>
                                      <tr>
                                        <th>External Marks Obtained</th>
                                        {reportStat2?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <th
                                              className={`${
                                                j?.e_min == 0
                                                  ? "text-danger"
                                                  : j?.e_marks +
                                                      Number(
                                                        j?.e_grace_marks
                                                      ) >=
                                                    j?.e_min
                                                  ? "text-success"
                                                  : "text-danger"
                                              } text-center`}
                                            >
                                              {!j?.e_marks && j?.e_absent ? (
                                                "Absent"
                                              ) : (
                                                <>
                                                  {j?.e_marks}{" "}
                                                  {j?.e_grace_marks
                                                    ? " + " + j?.e_grace_marks
                                                    : null}
                                                </>
                                              )}
                                            </th>
                                          ))}
                                      </tr>
                                      <tr>
                                        <th>Internal Max</th>
                                        {reportStat2?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <td className="text-center">
                                              {j?.i_max == 0 ? "--" : j?.i_max}
                                            </td>
                                          ))}
                                      </tr>
                                      <tr>
                                        <th>Internal Min</th>
                                        {reportStat2?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <td className="text-center">
                                              {j?.i_min == 0 ? "--" : j?.i_min}
                                            </td>
                                          ))}
                                      </tr>
                                      <tr>
                                        <th>Internal Marks Obtained</th>
                                        {reportStat2?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <th
                                              className={`${
                                                j?.i_marks >= j?.i_min
                                                  ? "text-success"
                                                  : "text-danger"
                                              } text-center`}
                                            >
                                              {j?.i_marks == 0
                                                ? "--"
                                                : j?.i_absent == 1
                                                ? "Absent"
                                                : j?.i_marks}
                                            </th>
                                          ))}
                                      </tr>
                                      <tr>
                                        <th>Credits</th>
                                        {reportStat2?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <td className="text-center">
                                              {j?.credit}
                                            </td>
                                          ))}
                                      </tr>
                                      <tr>
                                        <th>GPA</th>
                                        {reportStat2?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id
                                          )
                                          ?.map((j, key2) => (
                                            <td className="text-center">
                                              {calculateGradePoint(
                                                ((Number(j?.i_marks) +
                                                  Number(j?.e_marks) +
                                                  Number(j?.e_grace_marks)) /
                                                  (Number(j?.e_max) +
                                                    Number(j?.i_max))) *
                                                  Number(j?.credit)
                                              )}
                                            </td>
                                          ))}
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <th>Maximum Marks</th>
                                  <td>
                                    {reportStat?.marks
                                      ?.filter(
                                        (s) =>
                                          s?.student_id == i?.student_id &&
                                          !s.is_not_countable
                                      )
                                      ?.reduce(
                                        (total, cur) =>
                                          total +
                                          (Number(cur?.e_max) +
                                            Number(cur?.i_max)),
                                        0
                                      )}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Obtained Marks</th>
                                  <td>
                                    {reportStat?.marks
                                      ?.filter(
                                        (s) =>
                                          s?.student_id == i?.student_id &&
                                          !s.is_not_countable
                                      )
                                      ?.reduce(
                                        (total, cur) =>
                                          total +
                                          (Number(cur?.e_marks) +
                                            Number(cur?.i_marks) +
                                            Number(cur?.e_grace_marks)),
                                        0
                                      )}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Total Credits</th>
                                  <td>
                                    {reportStat?.marks
                                      ?.filter(
                                        (s) =>
                                          s?.student_id == i?.student_id &&
                                          !s.is_not_countable
                                      )
                                      ?.reduce(
                                        (total, j) => total + Number(j?.credit),
                                        0
                                      )}
                                  </td>
                                </tr>
                                <tr>
                                  <th>Total GPA</th>
                                  {AnalyseResult(i?.student_id) ? (
                                    <td>
                                      {reportStat?.marks
                                        ?.filter(
                                          (s) =>
                                            s?.student_id == i?.student_id &&
                                            !s.is_not_countable
                                        )
                                        ?.reduce(
                                          (total, j) =>
                                            total +
                                            Number(
                                              calculateGradePoint(
                                                ((Number(j?.i_marks) +
                                                  Number(j?.e_marks) +
                                                  Number(j?.e_grace_marks)) /
                                                  (Number(j?.e_max) +
                                                    Number(j?.i_max))) *
                                                  Number(j?.credit)
                                              )
                                            ),

                                          0
                                        )
                                        .toFixed(2)}
                                    </td>
                                  ) : (
                                    <td>
                                      <span className="text-danger"> -- </span>
                                    </td>
                                  )}
                                </tr>
                                <tr>
                                  <th>Percentage</th>
                                  <td>
                                    {(
                                      (reportStat?.marks
                                        ?.filter(
                                          (s) =>
                                            s?.student_id == i?.student_id &&
                                            !s.is_not_countable
                                        )
                                        ?.reduce(
                                          (total, cur) =>
                                            total +
                                            (Number(cur?.e_marks) +
                                              Number(cur?.i_marks) +
                                              Number(cur?.e_grace_marks)),
                                          0
                                        ) *
                                        100) /
                                      reportStat?.marks
                                        ?.filter(
                                          (s) =>
                                            s?.student_id == i?.student_id &&
                                            !s.is_not_countable
                                        )
                                        ?.reduce(
                                          (total, cur) =>
                                            total +
                                            (Number(cur?.e_max) +
                                              Number(cur?.i_max) +
                                              Number(cur?.e_grace_marks)),
                                          0
                                        )
                                    ).toFixed(2)}{" "}
                                    %
                                  </td>
                                </tr>
                                <tr>
                                  <th>SGPA</th>
                                  {AnalyseResult(i?.student_id) ? (
                                    <td>
                                      {(
                                        reportStat?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id &&
                                              !s.is_not_countable
                                          )
                                          ?.reduce(
                                            (total, j) =>
                                              total +
                                              Number(
                                                calculateGradePoint(
                                                  ((Number(j?.i_marks) +
                                                    Number(j?.e_marks) +
                                                    Number(j?.e_grace_marks)) /
                                                    (Number(j?.e_max) +
                                                      Number(j?.i_max))) *
                                                    Number(j?.credit)
                                                )
                                              ),
                                            0
                                          ) /
                                        reportStat?.marks
                                          ?.filter(
                                            (s) =>
                                              s?.student_id == i?.student_id &&
                                              !s.is_not_countable
                                          )
                                          ?.reduce(
                                            (total, j) =>
                                              total + Number(j?.credit),
                                            0
                                          )
                                      ).toFixed(2)}
                                      {/* {((Number(i?.i_marks) + Number(i?.e_marks)) * 10 / (Number(i?.i_max) + Number(i?.e_max))).toFixed(2)} % */}
                                    </td>
                                  ) : (
                                    <td>
                                      <span className="text-danger"> -- </span>
                                    </td>
                                  )}
                                </tr>
                                <tr>
                                  <th>Result</th>
                                  <th>
                                    {AnalyseResult(i?.student_id) ? (
                                      <span className="text-success">
                                        Pass{" "}
                                      </span>
                                    ) : (
                                      <span className="text-danger">Fail</span>
                                    )}
                                  </th>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {/*  Reguler Marksheet */}
                      {flag1 ? (
                        <div className="container" ref={tableRef1}>
                          <div className="image-container">
                            <div className="row overlay-container">
                              {reportStat?.studentData?.map((j, key) => {
                                let result = reportStat?.marks.filter(
                                  (s) => s.student_id == j?.student_id
                                );

                                result = result.sort((a, b) => {
                                  return a.arrangments - b.arrangments;
                                });

                                console.log(
                                  result?.filter(
                                    (s) => s.student_id == "SUK230502AE002"
                                  )
                                );

                                let examResults = Object.values(
                                  result.reduce((acc, curr) => {
                                    const subject = curr.subject;
                                    if (!acc[subject]) {
                                      acc[subject] = { ...curr };
                                    } else {
                                      acc[subject].e_marks += curr.e_marks;
                                      acc[subject].e_max += curr.e_max;
                                      acc[subject].e_min += curr.e_min;
                                      acc[subject].e_grace_marks +=
                                        Number(acc[subject].e_grace_marks) +
                                        Number(curr.e_grace_marks);
                                    }
                                    return acc;
                                  }, {})
                                );

                                let totalcredits = examResults
                                  ?.filter((s) => !s.is_not_countable)
                                  ?.reduce(
                                    (total, j) => total + Number(j?.credit),
                                    0
                                  );

                                let totalsum_imarks = examResults
                                  ?.filter((s) => !s.is_not_countable)
                                  ?.reduce(
                                    (total, j) => total + Number(j?.i_marks),
                                    0
                                  );

                                let totalsum_emarks = examResults
                                  ?.filter((s) => !s.is_not_countable)
                                  ?.reduce(
                                    (total, j) =>
                                      total +
                                      Number(j?.e_marks) +
                                      Number(j?.e_grace_marks),
                                    0
                                  );
                                let internalObtained = examResults
                                  ?.filter((s) => !s.is_not_countable)
                                  ?.reduce(
                                    (acc, it) => acc + Number(it?.i_marks),
                                    0
                                  );
                                let externalObtained = examResults
                                  ?.filter((s) => !s.is_not_countable)
                                  ?.reduce(
                                    (acc, it) =>
                                      acc +
                                      it?.e_marks +
                                      Number(it?.e_grace_marks),
                                    0
                                  );
                                let totalsum_imax = examResults
                                  ?.filter((s) => !s.is_not_countable)
                                  ?.reduce(
                                    (total, j) => total + Number(j?.i_max),
                                    0
                                  );
                                let totalsum_imin = examResults
                                  ?.filter((s) => !s.is_not_countable)
                                  ?.reduce(
                                    (total, j) => total + Number(j?.i_min),
                                    0
                                  );
                                let totalsum_emax = examResults
                                  ?.filter((s) => !s.is_not_countable)
                                  ?.reduce(
                                    (total, j) => total + Number(j?.e_max),
                                    0
                                  );

                                let totalabsent = examResults?.filter(
                                  (s) => s.e_absent == 1
                                ).length;

                                let totalsum_emin = examResults
                                  ?.filter((s) => !s.is_not_countable)
                                  ?.reduce(
                                    (total, j) => total + Number(j?.e_min),
                                    0
                                  );

                                let totalsum_obt = examResults
                                  ?.filter((s) => !s.is_not_countable)
                                  ?.reduce(
                                    (total, j) =>
                                      total +
                                      Number(j?.i_marks) +
                                      Number(j?.e_marks) +
                                      Number(j?.e_grace_marks),
                                    0
                                  );

                                var flag = 0;
                                for (const item of examResults) {
                                  if (
                                    item.i_absent == 1 ||
                                    item.e_absent == 1
                                  ) {
                                    flag = 1;
                                    break;
                                  }
                                  if (
                                    item.e_min >
                                      item.e_grace_marks + item.e_marks &&
                                    item.e_grace_marks != null &&
                                    item.e_grace_marks != 0
                                  ) {
                                    flag = 1;
                                    break;
                                  } else if (
                                    item.e_min > item.e_marks &&
                                    (item.e_grace_marks == null ||
                                      item.e_grace_marks == 0)
                                  ) {
                                    flag = 1;
                                    break;
                                  }
                                }

                                return (
                                  <div className="col-md-12 bg">
                                    {/* <img
                                    src='/assets/images/signature/ResultTemplate.jpg'
                                    alt="Your Image3"
                                    className="background-image"
                                  /> */}

                                    <div className="table-responsive res ">
                                      <table className="table table-bordered result overlay-table">
                                        <thead className="thead-dark bgimg">
                                          <tr>
                                            <th>Academic Year</th>
                                            <th>
                                              Month and year of Examination
                                            </th>
                                            <th>Year/Sem</th>
                                            <th>Enrollment Number</th>
                                          </tr>
                                          <tr>
                                            <td>
                                              {user?.college_id != "1111000"
                                                ? SessionOpt1?.find(
                                                    (s) =>
                                                      s.id ==
                                                      examResults[0]?.e_session1
                                                  )?.name
                                                : SessionOpt1?.find(
                                                    (s) =>
                                                      s.id ==
                                                      examResults[
                                                        examResults.length - 1
                                                      ]?.e_session1
                                                  )?.name}
                                            </td>
                                            <td>
                                              {monthsOfYear[user?.month - 1]} -{" "}
                                              {user?.year}
                                            </td>
                                            <td>
                                              {examResults[0]?.college_id ==
                                                1111001 ||
                                              examResults[0]?.college_id ==
                                                1111007 ||
                                              (examResults[0]?.college_id ==
                                                1111003 &&
                                                examResults[0]?.e_session1 ==
                                                  2021)
                                                ? classOpt?.filter(
                                                    (s) =>
                                                      s.id == user?.class_id
                                                  )[0]?.name
                                                : semesterOpt?.find(
                                                    (s) =>
                                                      s.id == user?.semester_id
                                                  )?.name}
                                            </td>
                                            <td>
                                              {examResults[0]?.student_id}
                                            </td>
                                          </tr>
                                          <tr>
                                            <th>Faculty Name</th>
                                            <th colSpan="3">College Name</th>
                                          </tr>
                                          <tr>
                                            <td>
                                              {
                                                facultyData.find(
                                                  (item) =>
                                                    item.id ==
                                                    examResults[0]?.college_id
                                                )?.name
                                              }
                                            </td>

                                            <td colSpan="3">
                                              {
                                                facultyData.find(
                                                  (item) =>
                                                    item.id ==
                                                    examResults[0]?.college_id
                                                )?.colloge_name
                                              }
                                            </td>
                                          </tr>

                                          {examResults[0]?.program !=
                                          "DOCTORATE" ? (
                                            <>
                                              <tr>
                                                <th>Program</th>

                                                <td>
                                                  {examResults?.department ==
                                                  "BAMS"
                                                    ? "1st Prof BAMS"
                                                    : DeptOpt?.filter(
                                                        (s) =>
                                                          s.id ==
                                                          examResults[0]
                                                            ?.department_id
                                                      )[0]?.name}{" "}
                                                  {/* {examResults[0]?.department ==
                                                  "B.Com"
                                                    ? "Bachelor of Commerce"
                                                    : examResults[0]
                                                        ?.department ==
                                                      "B.Sc. Nursing"
                                                    ? examResults[0]?.semester
                                                    : examResults?.department ==
                                                      "Post Basic B.Sc. Nursing"
                                                    ? "1st Year "
                                                    : examResults?.department ==
                                                      "BAMS"
                                                    ? "1st Prof BAMS"
                                                    : DeptOpt?.filter(
                                                        (s) =>
                                                          s.id ==
                                                          examResults[0]
                                                            ?.department_id
                                                      )[0]?.name}{" "} */}
                                                </td>
                                                <th>Student Name</th>
                                                <td>{examResults[0]?.name}</td>
                                              </tr>
                                            </>
                                          ) : null}
                                        </thead>
                                      </table>

                                      <table className="table result table-bordered  ">
                                        <thead className="thead-dark bgimg">
                                          <tr>
                                            <th
                                              rowSpan="2"
                                              className="text-center"
                                            >
                                              Subject Code
                                            </th>
                                            <th
                                              rowSpan="2"
                                              className="text-center"
                                            >
                                              Subject Name
                                            </th>

                                            {totalcredits != 0 ? (
                                              <th
                                                rowSpan="2"
                                                className="text-center"
                                              >
                                                Credit
                                              </th>
                                            ) : (
                                              ""
                                            )}

                                            <th
                                              className="text-center"
                                              colSpan="3"
                                            >
                                              {" "}
                                              {examResults?.department ==
                                                "B.H.M.S." ||
                                              examResults?.department ==
                                                "BAMS" ||
                                              examResults?.department ==
                                                "LLB  ( 3 Years )" ||
                                              examResults?.department ==
                                                "LL.M ( Business Law )" ||
                                              examResults?.department ==
                                                "LL.M ( Criminal Law )" ||
                                              examResults?.college ==
                                                "DOCTORAL AND POST DOCTORAL PROGRAMME" ? (
                                                "University Exam"
                                              ) : (
                                                <span>
                                                  {" "}
                                                  University Exam <br />
                                                  {totalsum_imarks == 0 ||
                                                  totalsum_imarks == null
                                                    ? " "
                                                    : "Internal Evaluation"}{" "}
                                                </span>
                                              )}
                                            </th>
                                            {totalcredits != 0 ? (
                                              <th
                                                rowSpan="2"
                                                className="text-center"
                                              >
                                                Total
                                              </th>
                                            ) : (
                                              ""
                                            )}
                                            {totalcredits != 0 ? (
                                              <th
                                                rowSpan="2"
                                                className="text-center"
                                              >
                                                {" "}
                                                Grade
                                              </th>
                                            ) : (
                                              ""
                                            )}
                                            {totalcredits != 0 ? (
                                              <th
                                                rowSpan="2"
                                                className="text-center"
                                              >
                                                {" "}
                                                GP
                                              </th>
                                            ) : (
                                              ""
                                            )}
                                            {totalcredits != 0 ? (
                                              <th
                                                rowSpan="2"
                                                className="text-center"
                                              >
                                                {" "}
                                                CGP
                                              </th>
                                            ) : (
                                              ""
                                            )}
                                            <th
                                              rowSpan="2"
                                              className="text-center"
                                            >
                                              {" "}
                                              Result
                                            </th>
                                          </tr>
                                          <tr>
                                            <th className="text-center">Max</th>
                                            <th className="text-center">Min</th>
                                            <th className="text-center">
                                              Obtained
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {examResults &&
                                            examResults?.map((i, key) => (
                                              <tr key={key}>
                                                <td>{i?.sub_code}</td>
                                                <td>{i?.subject}</td>
                                                {totalcredits != 0 ? (
                                                  <td className="text-center">
                                                    {i?.credit}{" "}
                                                  </td>
                                                ) : (
                                                  ""
                                                )}

                                                <td className="text-center">
                                                  {i?.e_max == 0
                                                    ? "-"
                                                    : i?.e_max}{" "}
                                                  <br />{" "}
                                                  {i?.i_max == 0
                                                    ? ""
                                                    : i?.i_max}{" "}
                                                </td>

                                                <td className="text-center">
                                                  {i?.e_min == 0
                                                    ? "-"
                                                    : i?.e_min}{" "}
                                                  <br />{" "}
                                                  {i?.i_min == 0
                                                    ? ""
                                                    : i?.i_min}{" "}
                                                </td>

                                                <td className="text-center">
                                                  {i?.e_absent == 1 ? (
                                                    <span className="text-danger">
                                                      <b>A</b>
                                                    </span>
                                                  ) : i?.e_marks == null ? (
                                                    "-"
                                                  ) : i?.e_marks == "" &&
                                                    i?.e_marks != 0 ? (
                                                    "-"
                                                  ) : i?.e_grace_marks ==
                                                      null ||
                                                    i?.e_grace_marks == 0 ? (
                                                    i?.e_marks
                                                  ) : (
                                                    i?.e_marks +
                                                    "+" +
                                                    i?.e_grace_marks
                                                  )}{" "}
                                                  <br />
                                                  {i?.i_absent == 1 ? (
                                                    <span className="text-danger">
                                                      <b>A</b>
                                                    </span>
                                                  ) : i?.i_max == null ||
                                                    i?.i_max == 0 ? (
                                                    ""
                                                  ) : (
                                                    i?.i_marks
                                                  )}
                                                </td>

                                                {/* Total here */}
                                                {totalcredits != 0 ? (
                                                  <td className="text-center">
                                                    {i?.e_grace_marks == null
                                                      ? Number(i?.i_marks) +
                                                        Number(i?.e_marks)
                                                      : Number(i?.i_marks) +
                                                        Number(i?.e_marks) +
                                                        Number(
                                                          i?.e_grace_marks
                                                        )}
                                                  </td>
                                                ) : (
                                                  ""
                                                )}

                                                {/* Grade here  */}
                                                {totalcredits != 0 ? (
                                                  <td className="text-center">
                                                    {i?.e_grace_marks == null
                                                      ? calculateGrade(
                                                          ((Number(i?.i_marks) +
                                                            Number(i?.e_marks) +
                                                            Number(
                                                              i?.e_grace_marks
                                                            )) /
                                                            (Number(i?.e_max) +
                                                              Number(
                                                                i?.i_max
                                                              ))) *
                                                            100
                                                        )
                                                      : calculateGrade(
                                                          ((Number(i?.i_marks) +
                                                            Number(i?.e_marks) +
                                                            Number(
                                                              i?.e_grace_marks
                                                            )) /
                                                            (Number(i?.e_max) +
                                                              Number(
                                                                i?.i_max
                                                              ))) *
                                                            100
                                                        )}
                                                  </td>
                                                ) : (
                                                  ""
                                                )}

                                                {/* GP Here */}
                                                {totalcredits != 0 ? (
                                                  <td className="text-center">
                                                    {i?.e_grace_marks == null
                                                      ? calculateGradePoint(
                                                          (Number(i?.i_marks) +
                                                            Number(i?.e_marks) +
                                                            Number(
                                                              i?.e_grace_marks
                                                            )) /
                                                            (Number(i?.e_max) +
                                                              Number(i?.i_max))
                                                        )
                                                      : calculateGradePoint(
                                                          (Number(i?.i_marks) +
                                                            Number(i?.e_marks) +
                                                            Number(
                                                              i?.e_grace_marks
                                                            )) /
                                                            (Number(i?.e_max) +
                                                              Number(i?.i_max))
                                                        )}{" "}
                                                  </td>
                                                ) : (
                                                  ""
                                                )}

                                                {/* Total CGP Here */}
                                                {totalcredits != 0 ? (
                                                  <td className="text-center">
                                                    {i?.e_grace_marks == null
                                                      ? calculateGradePoint(
                                                          ((Number(i?.i_marks) +
                                                            Number(i?.e_marks) +
                                                            Number(
                                                              i?.e_grace_marks
                                                            )) /
                                                            (Number(i?.e_max) +
                                                              Number(
                                                                i?.i_max
                                                              ))) *
                                                            Number(
                                                              i?.credit
                                                            ).toFixed(2)
                                                        )
                                                      : calculateGradePoint(
                                                          ((Number(i?.i_marks) +
                                                            Number(i?.e_marks) +
                                                            Number(
                                                              i?.e_grace_marks
                                                            )) /
                                                            (Number(i?.e_max) +
                                                              Number(
                                                                i?.i_max
                                                              ))) *
                                                            Number(
                                                              i?.credit
                                                            ).toFixed(2)
                                                        )}{" "}
                                                  </td>
                                                ) : (
                                                  ""
                                                )}

                                                <td className="text-center">
                                                  {/* {i?.data?.result}{" "} */}
                                                  {(Number(i?.i_marks) >=
                                                    i?.i_min &&
                                                    Number(i?.e_marks) +
                                                      Number(
                                                        i?.e_grace_marks
                                                      ) >=
                                                      i?.e_min) ||
                                                  (i?.e_marks == null &&
                                                    Number(
                                                      i?.i_absent != 1
                                                    )) ? (
                                                    <p className="text-success">
                                                      <b> P </b>{" "}
                                                    </p>
                                                  ) : (
                                                    //: (Number(i?.i_marks) >= i?.i_min &&
                                                    //     Number(i?.e_marks) + Number(i?.e_grace_marks) >=
                                                    //       i?.e_min) &&
                                                    //   i?.e_marks != "null"  ? (
                                                    //   <p className="text-success">
                                                    //     <b> P </b>{" "}
                                                    //   </p>
                                                    // )

                                                    <p className="text-danger">
                                                      <b>F </b>
                                                    </p>
                                                  )}{" "}
                                                </td>
                                              </tr>
                                            ))}

                                          <tr>
                                            <th className="text-center">
                                              Total
                                            </th>
                                            <td></td>
                                            {totalcredits != 0 ? (
                                              <td className="text-center">
                                                {" "}
                                                {totalcredits}{" "}
                                              </td>
                                            ) : (
                                              ""
                                            )}

                                            {/* 4th Td */}
                                            <td className="text-center">
                                              {totalsum_emax} <br />
                                              {totalsum_imax == 0 ||
                                              totalsum_imax == null
                                                ? ""
                                                : totalsum_imax}
                                            </td>

                                            {totalcredits == 0 ? (
                                              <td className="text-center">
                                                {examResults?.department ==
                                                "B.H.M.S."
                                                  ? totalsum_emin?.emin
                                                  : " "}
                                              </td>
                                            ) : (
                                              <td className="text-center">
                                                {totalsum_emin} <br />
                                                {totalsum_imin == 0
                                                  ? ""
                                                  : totalsum_imin}
                                              </td>
                                            )}
                                            {totalcredits != 0 ? (
                                              <td>
                                                {totalsum_emarks <= 0 ? (
                                                  <span className="text-danger">
                                                    <b>F</b>
                                                  </span>
                                                ) : (
                                                  <>
                                                    {totalsum_imin == 0 ||
                                                    totalsum_imin == null ? (
                                                      externalObtained
                                                    ) : (
                                                      <>
                                                        {externalObtained}
                                                        <br />
                                                        {internalObtained}
                                                      </>
                                                    )}

                                                    {/* {externalObtained}
                                                    <br />
                                                    {internalObtained} */}
                                                  </>
                                                )}
                                              </td>
                                            ) : (
                                              ""
                                            )}
                                            <td
                                              className="text-
                                            center"
                                            >
                                              {totalsum_emarks <= 0 ? (
                                                <span className="text-danger">
                                                  <b>F</b>
                                                </span>
                                              ) : totalsum_imarks == 0 ? (
                                                totalsum_emarks
                                              ) : (
                                                totalsum_imarks +
                                                totalsum_emarks
                                              )}
                                            </td>

                                            <th className="text-center">
                                              {examResults?.department != "BAMS"
                                                ? totalsum_obt?.obt
                                                : ""}
                                            </th>

                                            {/* Exactly */}
                                            {/* {totalcredits != 0 ? (
                                              <th colSpan={2}>
                                                {totalabsent >= 1
                                                  ? "-"
                                                  : examResults
                                                      ?.reduce(
                                                        (total, i) =>
                                                          i?.is_not_countable
                                                            ? total + 0
                                                            : total +
                                                              calculateGradePoint(
                                                                (Number(
                                                                  i?.i_marks
                                                                ) +
                                                                  Number(
                                                                    i?.e_marks
                                                                  ) +
                                                                  Number(
                                                                    i?.e_grace_marks
                                                                  )) /
                                                                  (Number(
                                                                    i?.e_max
                                                                  ) +
                                                                    Number(
                                                                      i?.i_max
                                                                    ))
                                                              ) *
                                                                Number(
                                                                  i?.credit
                                                                ),
                                                        0
                                                      )
                                                      .toFixed(2)}
                                              </th>
                                            ) : (
                                              ""
                                            )} */}

                                            {totalcredits != 0 ? <th></th> : ""}
                                            {/* Here THE CGP */}
                                            {totalcredits != 0 ? (
                                              <th className="text-center">
                                                {totalabsent >= 1 ||
                                                !AnalyseResult(j?.student_id)
                                                  ? "-"
                                                  : result
                                                      ?.filter(
                                                        (s) =>
                                                          !s.is_not_countable
                                                      )
                                                      ?.reduce(
                                                        (total, j) =>
                                                          total +
                                                          Number(
                                                            calculateGradePoint(
                                                              ((Number(
                                                                j?.i_marks
                                                              ) +
                                                                Number(
                                                                  j?.e_marks
                                                                ) +
                                                                Number(
                                                                  j?.e_grace_marks
                                                                )) /
                                                                (Number(
                                                                  j?.e_max
                                                                ) +
                                                                  Number(
                                                                    j?.i_max
                                                                  ))) *
                                                                Number(
                                                                  j?.credit
                                                                )
                                                            )
                                                          ),

                                                        0
                                                      )
                                                      .toFixed(2)}
                                              </th>
                                            ) : (
                                              ""
                                            )}
                                            {totalcredits != 0 ? <th></th> : ""}
                                          </tr>
                                          <tr className="text-center">
                                            <th className="text-center">
                                              {" "}
                                              Result{" "}
                                            </th>
                                            <td
                                              colSpan={5}
                                              className="text-center"
                                            >
                                              {totalabsent >= 1 ||
                                              examResults?.reduce(
                                                (total, i) =>
                                                  total +
                                                  (calculateGradePoint(
                                                    (Number(i?.i_marks) +
                                                      Number(i?.e_marks) +
                                                      Number(
                                                        i?.e_grace_marks
                                                      )) /
                                                      (Number(i?.e_max) +
                                                        Number(i?.i_max))
                                                  ) <
                                                    3.6),
                                                0
                                              ) ||
                                              flag ? (
                                                <p className="text-danger">
                                                  <b> FAIL </b>{" "}
                                                </p>
                                              ) : (
                                                <p className="text-success">
                                                  <b>PASS </b>
                                                </p>
                                              )}
                                            </td>

                                            {totalcredits != 0 ? (
                                              <th>SGPA:</th>
                                            ) : (
                                              ""
                                            )}

                                            {totalcredits != 0 ? (
                                              <th
                                                colSpan={4}
                                                className="text-center"
                                              >
                                                {totalabsent >= 1 ||
                                                !AnalyseResult(j?.student_id)
                                                  ? "-"
                                                  : (
                                                      examResults
                                                        ?.filter(
                                                          (s) =>
                                                            !s.is_not_countable
                                                        )
                                                        ?.reduce(
                                                          (total, j) =>
                                                            total +
                                                            Number(
                                                              calculateGradePoint(
                                                                ((Number(
                                                                  j?.i_marks
                                                                ) +
                                                                  Number(
                                                                    j?.e_marks
                                                                  ) +
                                                                  Number(
                                                                    j?.e_grace_marks
                                                                  )) /
                                                                  (Number(
                                                                    j?.e_max
                                                                  ) +
                                                                    Number(
                                                                      j?.i_max
                                                                    ))) *
                                                                  Number(
                                                                    j?.credit
                                                                  )
                                                              )
                                                            ),
                                                          0
                                                        ) / totalcredits
                                                    ).toFixed(2)}
                                              </th>
                                            ) : (
                                              ""
                                            )}
                                          </tr>
                                          {/* <tr>
                                            {semArr?.map((item, key) => {
                                              return <th>{item?.value}</th>;
                                            })}
                                          </tr> */}
                                          {/* <tr>
                                            {semArr?.map((item, key) => {
                                              return (
                                                <td>
                                                  {prevExamData?.filter(
                                                    (s) =>
                                                      s.student_id ==
                                                        j?.student_id &&
                                                      s.sem_name.trim() ==
                                                        item?.name
                                                  )?.length > 0
                                                    ? (prevExamData
                                                        ?.filter(
                                                          (s) =>
                                                            s.student_id ==
                                                              j?.student_id &&
                                                            s.sem_name.trim() ==
                                                              item.name &&
                                                            !s.is_not_countable
                                                        )
                                                        ?.reduce(
                                                          (total, i) =>
                                                            total +
                                                            (calculateGradePoint(
                                                              (Number(
                                                                i?.i_marks
                                                              ) +
                                                                Number(
                                                                  i?.e_marks
                                                                ) +
                                                                Number(
                                                                  i?.e_grace_marks
                                                                )) /
                                                                (Number(
                                                                  i?.e_max
                                                                ) +
                                                                  Number(
                                                                    i?.i_max
                                                                  ))
                                                            ) <
                                                              3.6),
                                                          0
                                                        )
                                                        ? (prevExamData
                                                            ?.filter(
                                                              (s) =>
                                                                s.student_id ==
                                                                  j?.student_id &&
                                                                s.sem_name.trim() ==
                                                                  item.name &&
                                                                !s.is_not_countable &&
                                                                s.exam_type ==
                                                                  "regular" &&
                                                                prevExamData?.filter(
                                                                  (s1) =>
                                                                    s1.student_id ==
                                                                      j?.student_id &&
                                                                    s1.sem_name.trim() ==
                                                                      item.name &&
                                                                    !s1.is_not_countable &&
                                                                    s1.subject ==
                                                                      s.subject
                                                                )?.length <= 1
                                                            )
                                                            ?.reduce(
                                                              (total, k) =>
                                                                total +
                                                                Number(
                                                                  calculateGradePoint(
                                                                    ((Number(
                                                                      k?.i_marks
                                                                    ) +
                                                                      Number(
                                                                        k?.e_marks
                                                                      ) +
                                                                      Number(
                                                                        k?.e_grace_marks
                                                                      )) /
                                                                      (Number(
                                                                        k?.e_max
                                                                      ) +
                                                                        Number(
                                                                          k?.i_max
                                                                        ))) *
                                                                      Number(
                                                                        k?.credit
                                                                      )
                                                                  )
                                                                ),
                                                              0
                                                            ) +
                                                            prevExamData
                                                              ?.filter(
                                                                (s) =>
                                                                  s.student_id ==
                                                                    j?.student_id &&
                                                                  s.sem_name.trim() ==
                                                                    item.name &&
                                                                  !s.is_not_countable &&
                                                                  s.exam_type ==
                                                                    "reexam"
                                                              )
                                                              ?.reduce(
                                                                (total, k) =>
                                                                  total +
                                                                  Number(
                                                                    calculateGradePoint(
                                                                      ((Number(
                                                                        k?.i_marks
                                                                      ) +
                                                                        Number(
                                                                          k?.e_marks
                                                                        ) +
                                                                        Number(
                                                                          k?.e_grace_marks
                                                                        )) /
                                                                        (Number(
                                                                          k?.e_max
                                                                        ) +
                                                                          Number(
                                                                            k?.i_max
                                                                          ))) *
                                                                        Number(
                                                                          k?.credit
                                                                        )
                                                                    )
                                                                  ),
                                                                0
                                                              )) /
                                                          prevExamData
                                                            ?.filter(
                                                              (s) =>
                                                                s.student_id ==
                                                                  j?.student_id &&
                                                                s.sem_name.trim() ==
                                                                  item.name &&
                                                                !s.is_not_countable &&
                                                                s.exam_type ==
                                                                  "regular"
                                                            )
                                                            ?.reduce(
                                                              (acc, it) =>
                                                                acc +
                                                                Number(
                                                                  it.credit
                                                                ),
                                                              0
                                                            )
                                                            .toFixed(2)
                                                        : prevExamData
                                                            ?.filter(
                                                              (s) =>
                                                                s.student_id ==
                                                                  j?.student_id &&
                                                                s.sem_name.trim() ==
                                                                  item.name &&
                                                                !s.is_not_countable
                                                            )
                                                            ?.reduce(
                                                              (total, k) =>
                                                                total +
                                                                Number(
                                                                  calculateGradePoint(
                                                                    ((Number(
                                                                      k?.i_marks
                                                                    ) +
                                                                      Number(
                                                                        k?.e_marks
                                                                      ) +
                                                                      Number(
                                                                        k?.e_grace_marks
                                                                      )) /
                                                                      (Number(
                                                                        k?.e_max
                                                                      ) +
                                                                        Number(
                                                                          k?.i_max
                                                                        ))) *
                                                                      Number(
                                                                        k?.credit
                                                                      )
                                                                  )
                                                                ),
                                                              0
                                                            ) /
                                                          prevExamData
                                                            ?.filter(
                                                              (s) =>
                                                                s.student_id ==
                                                                  j?.student_id &&
                                                                s.sem_name.trim() ==
                                                                  item.name &&
                                                                !s.is_not_countable
                                                            )
                                                            ?.reduce(
                                                              (acc, it) =>
                                                                acc +
                                                                Number(
                                                                  it.credit
                                                                ),
                                                              0
                                                            )
                                                      ).toFixed(2)
                                                    : "-"}
                                                </td>
                                              );
                                            })}
                                          </tr> */}
                                        </tbody>
                                      </table>
                                      <div className="text-center mt-4">
                                        <h6>Nomenclature / Abbreviations</h6>
                                      </div>
                                      <div className="d-flex justify-content-around px-5 ">
                                        <h6> P {"->"} Pass</h6>
                                        <h6> F {"->"} Fail</h6>
                                        <h6> A {"->"} Absent</h6>
                                        <h6> NE {"->"} Not Eligible</h6>
                                      </div>
                                      {/* <h5>This is Computer Generated Marksheet</h5> */}
                                    </div>
                                    <p className="date">{formattedDate}</p>
                                    <img
                                      src="/assets/images/signature/coe sign.png"
                                      alt=""
                                      className="coesign"
                                      width={180}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {/* NEP MarksSheet */}

                      {flag1 ? (
                        <div className="container" ref={tableRefNep}>
                          <div className="image-container">
                            <div className="row overlay-container">
                              {reportStat?.studentData?.map((j, key) => {
                                let result = reportStat?.marks.filter(
                                  (s) => s.student_id == j?.student_id
                                );

                                let examResults = Object.values(
                                  result.reduce((acc, curr) => {
                                    const subject = curr.subject;
                                    if (!acc[subject]) {
                                      acc[subject] = { ...curr };
                                    } else {
                                      acc[subject].e_marks += curr.e_marks;
                                      acc[subject].e_max += curr.e_max;
                                      acc[subject].e_min += curr.e_min;

                                      acc[subject].i_marks += curr.i_marks;
                                      acc[subject].i_max += curr.i_max;
                                      acc[subject].i_min += curr.i_min;
                                      acc[subject].e_grace_marks =
                                        Number(acc[subject].e_grace_marks) +
                                        Number(curr.e_grace_marks);
                                    }
                                    return acc;
                                  }, {})
                                );

                                let totalcredits = examResults?.reduce(
                                  (total, j) => total + Number(j?.credit),
                                  0
                                );

                                let totalsum_imarks = examResults?.reduce(
                                  (total, j) => total + Number(j?.i_marks),
                                  0
                                );

                                let totalsum_emarks = examResults?.reduce(
                                  (total, j) =>
                                    total +
                                    Number(j?.e_marks) +
                                    Number(j?.e_grace_marks),
                                  0
                                );

                                let internalObtained = examResults?.reduce(
                                  (acc, it) => acc + Number(it?.i_marks),
                                  0
                                );

                                let externalObtained = examResults?.reduce(
                                  (acc, it) =>
                                    acc +
                                    it?.e_marks +
                                    Number(it?.e_grace_marks),
                                  0
                                );

                                let totalsum_imax = examResults?.reduce(
                                  (total, j) => total + Number(j?.i_max),
                                  0
                                );

                                let totalsum_imin = examResults?.reduce(
                                  (total, j) => total + Number(j?.i_min),
                                  0
                                );

                                let totalsum_emax = examResults?.reduce(
                                  (total, j) => total + Number(j?.e_max),
                                  0
                                );

                                let totalabsent = examResults?.filter(
                                  (s) => s.e_absent == 1
                                ).length;

                                let totalsum_emin = examResults?.reduce(
                                  (total, j) => total + Number(j?.e_min),
                                  0
                                );

                                let totalsum_obt = examResults?.reduce(
                                  (total, j) =>
                                    total +
                                    Number(j?.i_marks) +
                                    Number(j?.e_marks) +
                                    Number(j?.e_grace_marks),
                                  0
                                );

                                var flag = 0;
                                for (const item of examResults) {
                                  if (
                                    item.i_absent == 1 ||
                                    item.e_absent == 1
                                  ) {
                                    flag = 1;
                                    break;
                                  }
                                  if (
                                    item.e_min >
                                      item.e_grace_marks + item.e_marks &&
                                    item.e_grace_marks != null &&
                                    item.e_grace_marks != 0
                                  ) {
                                    flag = 1;
                                    break;
                                  } else if (
                                    item.e_min > item.e_marks &&
                                    (item.e_grace_marks == null ||
                                      item.e_grace_marks == 0)
                                  ) {
                                    flag = 1;
                                    break;
                                  }
                                }

                                return (
                                  <div className="col-md-12 bg">
                                    <img
                                      src="ResultTemplate.jpg"
                                      alt="Your Image2"
                                      className="background-image"
                                    />

                                    <div className="table-responsive res ">
                                      <table className="table padding table-bordered result overlay-table">
                                        <tbody className="thead-dark text-left bgimg ">
                                          <tr>
                                            <th>Academic Year</th>
                                            <td>
                                              {user?.college_id != "1111000"
                                                ? SessionOpt1?.find(
                                                    (s) =>
                                                      s.id ==
                                                      examResults[0]?.e_session1
                                                  )?.name
                                                : SessionOpt1?.find(
                                                    (s) =>
                                                      s.id ==
                                                      examResults[
                                                        examResults.length - 1
                                                      ]?.e_session1
                                                  )?.name}
                                            </td>
                                            <th>Name of Student</th>
                                            <td>{examResults[0]?.name}</td>
                                          </tr>
                                          <tr>
                                            <th>Programme Name</th>
                                            <td>
                                              {" "}
                                              {examResults?.department == "BAMS"
                                                ? "1st Prof BAMS"
                                                : DeptOpt?.filter(
                                                    (s) =>
                                                      s.id ==
                                                      examResults[0]
                                                        ?.department_id
                                                  )[0]?.name}{" "}
                                            </td>
                                            <th>Name of Father and Mother</th>
                                            <td>
                                              {examResults[0]?.father_name} -{" "}
                                              {examResults[0]?.mother_name}
                                            </td>
                                          </tr>
                                          <tr>
                                            <th>Enrollment No.</th>
                                            <td>
                                              {" "}
                                              {examResults[0]?.student_id}
                                            </td>
                                            <th>
                                              Month and Year of Examination
                                            </th>
                                            <td>
                                              {" "}
                                              {
                                                monthsOfYear[user?.month - 1]
                                              } - {user?.year}
                                            </td>
                                          </tr>
                                          <tr>
                                            <th>Semester</th>
                                            <td>
                                              {" "}
                                              {examResults[0]?.college_id ==
                                                1111001 ||
                                              examResults[0]?.college_id ==
                                                1111007 ||
                                              (examResults[0]?.college_id ==
                                                1111003 &&
                                                examResults[0]?.e_session1 ==
                                                  2021)
                                                ? classOpt?.filter(
                                                    (s) =>
                                                      s.id == user?.class_id
                                                  )[0]?.name
                                                : semesterOpt?.find(
                                                    (s) =>
                                                      s.id == user?.semester_id
                                                  )?.name}
                                            </td>
                                            <th>Institute</th>
                                            <td>
                                              {" "}
                                              {
                                                facultyData.find(
                                                  (item) =>
                                                    item.id ==
                                                    examResults[0]?.college_id
                                                )?.colloge_name
                                              }
                                            </td>
                                          </tr>
                                          <tr>
                                            <th>ABC-ID's</th>
                                            <td>
                                              {examResults[0]?.abc_number}
                                            </td>
                                            <th>Major/Minor</th>
                                            <td></td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <table className="table result table-bordered  ">
                                        <thead className="thead-dark bgimg">
                                          <tr>
                                            <th rowSpan={2}>Subject Code</th>
                                            <th rowSpan={2}>Subject Name</th>
                                            <th>Subject / Type</th>
                                            <th>Max</th>
                                            <th>Min</th>
                                            <th>CEE</th>
                                            <th>SEE</th>
                                            <th>TOTAL</th>
                                            <th>CREDIT</th>
                                            <th>GRADE</th>
                                            <th>GP</th>
                                            <th>CGP</th>
                                          </tr>
                                        </thead>

                                        <tbody>
                                          {examResults &&
                                            examResults?.map((i, key) => {
                                              let x = examResults?.find(
                                                (s) =>
                                                  s.sub_code == i?.sub_code &&
                                                  s.type == "PRACTICAL" &&
                                                  s.student_id == i?.student_id
                                              );
                                              if (
                                                examResults?.filter(
                                                  (s) =>
                                                    s.sub_code == i?.sub_code
                                                )?.length == 1
                                              ) {
                                                return (
                                                  <tr key={key}>
                                                    <td>{i?.sub_code}</td>
                                                    <td>{i?.subject}</td>
                                                    <td>{i?.type}</td>

                                                    <td className="text-center">
                                                      {i?.e_max == 0
                                                        ? "-"
                                                        : i?.e_max}
                                                    </td>

                                                    <td className="text-center">
                                                      {i?.e_min == 0
                                                        ? "-"
                                                        : i?.e_min}
                                                    </td>

                                                    {/* CEE Here */}
                                                    <td className="text-center">
                                                      {examResults
                                                        ?.filter(
                                                          (s) =>
                                                            s.student_id ==
                                                              j?.student_id &&
                                                            s.sub_code ==
                                                              i?.sub_code
                                                        )
                                                        ?.reduce(
                                                          (acc, it) =>
                                                            acc + it?.i_marks,
                                                          0
                                                        )}
                                                    </td>

                                                    {/* SEE Here */}
                                                    <td className="text-center">
                                                      {examResults
                                                        ?.filter(
                                                          (s) =>
                                                            s.student_id ==
                                                              j?.student_id &&
                                                            s.sub_code ==
                                                              i?.sub_code
                                                        )
                                                        ?.reduce(
                                                          (acc, it) =>
                                                            acc + it?.e_marks,
                                                          0
                                                        )}
                                                    </td>

                                                    {/* Total here */}
                                                    <td className="text-center">
                                                      {i?.e_grace_marks == null
                                                        ? Number(i?.i_marks) +
                                                          Number(i?.e_marks)
                                                        : Number(i?.i_marks) +
                                                          Number(i?.e_marks) +
                                                          Number(
                                                            i?.e_grace_marks
                                                          )}
                                                    </td>

                                                    <td className="text-center">
                                                      {i?.credit}{" "}
                                                    </td>
                                                    {/* Grade here  */}
                                                    {totalcredits != 0 ? (
                                                      <td className="text-center">
                                                        {i?.e_grace_marks ==
                                                        null
                                                          ? calculateGrade1(
                                                              ((Number(
                                                                i?.i_marks
                                                              ) +
                                                                Number(
                                                                  i?.e_marks
                                                                ) +
                                                                Number(
                                                                  i?.e_grace_marks
                                                                )) /
                                                                (Number(
                                                                  i?.e_max
                                                                ) +
                                                                  Number(
                                                                    i?.i_max
                                                                  ))) *
                                                                100
                                                            )
                                                          : calculateGrade1(
                                                              ((Number(
                                                                i?.i_marks
                                                              ) +
                                                                Number(
                                                                  i?.e_marks
                                                                ) +
                                                                Number(
                                                                  i?.e_grace_marks
                                                                )) /
                                                                (Number(
                                                                  i?.e_max
                                                                ) +
                                                                  Number(
                                                                    i?.i_max
                                                                  ))) *
                                                                100
                                                            )}
                                                      </td>
                                                    ) : (
                                                      ""
                                                    )}

                                                    <td className="text-center">
                                                      {i?.e_grace_marks == null
                                                        ? calculateGradePoint(
                                                            (Number(
                                                              i?.i_marks
                                                            ) +
                                                              Number(
                                                                i?.e_marks
                                                              ) +
                                                              Number(
                                                                i?.e_grace_marks
                                                              )) /
                                                              (Number(
                                                                i?.e_max
                                                              ) +
                                                                Number(
                                                                  i?.i_max
                                                                ))
                                                          )
                                                        : calculateGradePoint(
                                                            (Number(
                                                              i?.i_marks
                                                            ) +
                                                              Number(
                                                                i?.e_marks
                                                              ) +
                                                              Number(
                                                                i?.e_grace_marks
                                                              )) /
                                                              (Number(
                                                                i?.e_max
                                                              ) +
                                                                Number(
                                                                  i?.i_max
                                                                ))
                                                          )}{" "}
                                                    </td>

                                                    <td className="text-center">
                                                      {i?.e_grace_marks == null
                                                        ? calculateGradePoint(
                                                            ((Number(
                                                              i?.i_marks
                                                            ) +
                                                              Number(
                                                                i?.e_marks
                                                              ) +
                                                              Number(
                                                                i?.e_grace_marks
                                                              )) /
                                                              (Number(
                                                                i?.e_max
                                                              ) +
                                                                Number(
                                                                  i?.i_max
                                                                ))) *
                                                              Number(
                                                                i?.credit
                                                              ).toFixed(2)
                                                          )
                                                        : calculateGradePoint(
                                                            ((Number(
                                                              i?.i_marks
                                                            ) +
                                                              Number(
                                                                i?.e_marks
                                                              ) +
                                                              Number(
                                                                i?.e_grace_marks
                                                              )) /
                                                              (Number(
                                                                i?.e_max
                                                              ) +
                                                                Number(
                                                                  i?.i_max
                                                                ))) *
                                                              Number(
                                                                i?.credit
                                                              ).toFixed(2)
                                                          )}{" "}
                                                    </td>
                                                  </tr>
                                                );
                                              } else if (i.type == "LECTURE") {
                                                return (
                                                  <tr key={key}>
                                                    <td>{i?.sub_code}</td>
                                                    <td>{i?.subject}</td>
                                                    <td>
                                                      {i?.type}
                                                      <br />
                                                      {"PRACTICAL"}
                                                    </td>

                                                    <td className="text-center">
                                                      {i?.e_max == 0
                                                        ? "-"
                                                        : i?.e_max}
                                                      <br />
                                                      {x?.e_max}
                                                    </td>

                                                    <td className="text-center">
                                                      {i?.e_min == 0
                                                        ? "-"
                                                        : i?.e_min}
                                                      <br />
                                                      {x?.e_min}
                                                    </td>

                                                    {/* CEE Here */}
                                                    <td className="text-center">
                                                      {examResults
                                                        ?.filter(
                                                          (s) =>
                                                            s.student_id ==
                                                              j?.student_id &&
                                                            s.sub_code ==
                                                              i?.sub_code &&
                                                            s.type == i?.type
                                                        )
                                                        ?.reduce(
                                                          (acc, it) =>
                                                            acc + it?.e_marks,
                                                          0
                                                        )}
                                                      <br />
                                                      {x?.e_marks}
                                                    </td>

                                                    {/* SEE Here */}
                                                    <td className="text-center">
                                                      {examResults
                                                        ?.filter(
                                                          (s) =>
                                                            s.student_id ==
                                                              j?.student_id &&
                                                            s.sub_code ==
                                                              i?.sub_code &&
                                                            s.type == i?.type
                                                        )
                                                        ?.reduce(
                                                          (acc, it) =>
                                                            acc + it?.i_marks,
                                                          0
                                                        )}
                                                      <br />
                                                      {x?.i_marks}
                                                    </td>

                                                    {/* Total here */}
                                                    <td className="text-center">
                                                      {i?.e_grace_marks == null
                                                        ? Number(i?.i_marks) +
                                                          Number(i?.e_marks)
                                                        : Number(i?.i_marks) +
                                                          Number(i?.e_marks) +
                                                          Number(
                                                            i?.e_grace_marks
                                                          )}
                                                      <br />
                                                      {x?.e_grace_marks == null
                                                        ? Number(x?.i_marks) +
                                                          Number(x?.e_marks)
                                                        : Number(x?.i_marks) +
                                                          Number(x?.e_marks) +
                                                          Number(
                                                            x?.e_grace_marks
                                                          )}
                                                    </td>

                                                    <td className="text-center">
                                                      {i?.credit} <br />
                                                      {x?.credit}
                                                    </td>
                                                    {/* Grade here  */}
                                                    {totalcredits != 0 ? (
                                                      <td className="text-center">
                                                        {i?.e_grace_marks ==
                                                        null
                                                          ? calculateGrade1(
                                                              ((Number(
                                                                i?.i_marks
                                                              ) +
                                                                Number(
                                                                  i?.e_marks
                                                                ) +
                                                                Number(
                                                                  i?.e_grace_marks
                                                                )) /
                                                                (Number(
                                                                  i?.e_max
                                                                ) +
                                                                  Number(
                                                                    i?.i_max
                                                                  ))) *
                                                                100
                                                            )
                                                          : calculateGrade1(
                                                              ((Number(
                                                                i?.i_marks
                                                              ) +
                                                                Number(
                                                                  i?.e_marks
                                                                ) +
                                                                Number(
                                                                  i?.e_grace_marks
                                                                )) /
                                                                (Number(
                                                                  i?.e_max
                                                                ) +
                                                                  Number(
                                                                    i?.i_max
                                                                  ))) *
                                                                100
                                                            )}
                                                        <br />
                                                        {x?.e_grace_marks ==
                                                        null
                                                          ? calculateGrade1(
                                                              ((Number(
                                                                x?.i_marks
                                                              ) +
                                                                Number(
                                                                  x?.e_marks
                                                                ) +
                                                                Number(
                                                                  x?.e_grace_marks
                                                                )) /
                                                                (Number(
                                                                  x?.e_max
                                                                ) +
                                                                  Number(
                                                                    x?.i_max
                                                                  ))) *
                                                                100
                                                            )
                                                          : calculateGrade1(
                                                              ((Number(
                                                                x?.i_marks
                                                              ) +
                                                                Number(
                                                                  x?.e_marks
                                                                ) +
                                                                Number(
                                                                  x?.e_grace_marks
                                                                )) /
                                                                (Number(
                                                                  x?.e_max
                                                                ) +
                                                                  Number(
                                                                    x?.i_max
                                                                  ))) *
                                                                100
                                                            )}
                                                      </td>
                                                    ) : (
                                                      ""
                                                    )}

                                                    <td className="text-center">
                                                      {i?.e_grace_marks == null
                                                        ? calculateGradePoint(
                                                            (Number(
                                                              i?.i_marks
                                                            ) +
                                                              Number(
                                                                i?.e_marks
                                                              ) +
                                                              Number(
                                                                i?.e_grace_marks
                                                              )) /
                                                              (Number(
                                                                i?.e_max
                                                              ) +
                                                                Number(
                                                                  i?.i_max
                                                                ))
                                                          )
                                                        : calculateGradePoint(
                                                            (Number(
                                                              i?.i_marks
                                                            ) +
                                                              Number(
                                                                i?.e_marks
                                                              ) +
                                                              Number(
                                                                i?.e_grace_marks
                                                              )) /
                                                              (Number(
                                                                i?.e_max
                                                              ) +
                                                                Number(
                                                                  i?.i_max
                                                                ))
                                                          )}{" "}
                                                      <br />
                                                      {x?.e_grace_marks == null
                                                        ? calculateGradePoint(
                                                            (Number(
                                                              x?.i_marks
                                                            ) +
                                                              Number(
                                                                x?.e_marks
                                                              ) +
                                                              Number(
                                                                x?.e_grace_marks
                                                              )) /
                                                              (Number(
                                                                x?.e_max
                                                              ) +
                                                                Number(
                                                                  x?.i_max
                                                                ))
                                                          )
                                                        : calculateGradePoint(
                                                            (Number(
                                                              x?.i_marks
                                                            ) +
                                                              Number(
                                                                x?.e_marks
                                                              ) +
                                                              Number(
                                                                x?.e_grace_marks
                                                              )) /
                                                              (Number(
                                                                x?.e_max
                                                              ) +
                                                                Number(
                                                                  x?.i_max
                                                                ))
                                                          )}
                                                    </td>

                                                    <td className="text-center">
                                                      {i?.e_grace_marks == null
                                                        ? calculateGradePoint(
                                                            ((Number(
                                                              i?.i_marks
                                                            ) +
                                                              Number(
                                                                i?.e_marks
                                                              ) +
                                                              Number(
                                                                i?.e_grace_marks
                                                              )) /
                                                              (Number(
                                                                i?.e_max
                                                              ) +
                                                                Number(
                                                                  i?.i_max
                                                                ))) *
                                                              Number(
                                                                i?.credit
                                                              ).toFixed(2)
                                                          )
                                                        : calculateGradePoint(
                                                            ((Number(
                                                              i?.i_marks
                                                            ) +
                                                              Number(
                                                                i?.e_marks
                                                              ) +
                                                              Number(
                                                                i?.e_grace_marks
                                                              )) /
                                                              (Number(
                                                                i?.e_max
                                                              ) +
                                                                Number(
                                                                  i?.i_max
                                                                ))) *
                                                              Number(
                                                                i?.credit
                                                              ).toFixed(2)
                                                          )}
                                                      <br />
                                                      {x?.e_grace_marks == null
                                                        ? calculateGradePoint(
                                                            ((Number(
                                                              x?.i_marks
                                                            ) +
                                                              Number(
                                                                x?.e_marks
                                                              ) +
                                                              Number(
                                                                x?.e_grace_marks
                                                              )) /
                                                              (Number(
                                                                x?.e_max
                                                              ) +
                                                                Number(
                                                                  x?.i_max
                                                                ))) *
                                                              Number(
                                                                x?.credit
                                                              ).toFixed(2)
                                                          )
                                                        : calculateGradePoint(
                                                            ((Number(
                                                              x?.i_marks
                                                            ) +
                                                              Number(
                                                                x?.e_marks
                                                              ) +
                                                              Number(
                                                                x?.e_grace_marks
                                                              )) /
                                                              (Number(
                                                                x?.e_max
                                                              ) +
                                                                Number(
                                                                  x?.i_max
                                                                ))) *
                                                              Number(
                                                                x?.credit
                                                              ).toFixed(2)
                                                          )}{" "}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            })}
                                        </tbody>
                                      </table>
                                      <table className="table result table-bordered  ">
                                        <tr>
                                          <th colSpan={7}>
                                            SEMESTER WISE RESULT
                                          </th>
                                        </tr>
                                        <tr>
                                          <td>Semester</td>
                                          <td>Sem-1</td>
                                          <td>Sem-2</td>

                                          <td>Sem-3</td>

                                          <td>Sem-4</td>

                                          <td>Sem-5</td>
                                          <td>Sem-6</td>
                                        </tr>
                                        <tr>
                                          <td>Total Credit</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                        <tr>
                                          <td>Obtained Credit</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                        <tr>
                                          <td>Additional Credit</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                        <tr>
                                          <td>SGPA</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                        <tr>
                                          <td>ATTEMPTS </td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                        <tr>
                                          <td>RESULT</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </table>
                                    </div>
                                    <p className="date">{formattedDate}</p>
                                    <img
                                      src="/assets/images/signature/coe sign.png"
                                      alt=""
                                      className="coesign"
                                      width={180}
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportDetailed;
